.p-article {
    &__header {
        position: relative;
        background-color: $yellowOrange;
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 50px;
            left: 0;
            bottom: 0;
            background: $white;
            z-index: 0;
            @include pc {
                height: 110px;
            }
        }
    }

    &__titleArea {
        padding-top: 70px;
        @include pc {
            padding-top: 90px;
        }
    }

    &__title {
        margin: 0 10px 30px;
        background-color: $white;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        z-index: 2;
        @include pc {
            width: 950px;
            margin: 0 auto 0;
        }
        & > h1 {
            font-size: 1.8rem;
            line-height: 2.8rem;
            font-weight: $font-bold;
            padding: 20px 10px;
            @include pc {
                font-size: 3.4rem;
                line-height: 5.0rem;
                padding: 30px;
            }
            & > strong {
                background: linear-gradient(transparent 60%, $yellowOrange 60%);
            }
        }
        & > img {
            width: 100%;
        }
    }

    &__lead {
        margin: 30px 20px;
        @include pc {
            width: 950px;
            margin: 30px auto 40px;
            font-size: 1.8rem;
            line-height: 3.2rem;
        }
    }

    &__detail {
        margin: 30px 0 30px;
        @include pc {
            margin: 40px auto;
            display: flex;
            justify-content: space-between;
            width: 950px;
        }
    }

    &__overview {
        border: 2px solid $yellowOrange;
        border-radius: 20px;
        overflow: hidden;
        margin: 0 20px 20px;
        @include pc {
            width: calc(50% - 15px);
            margin: 0;
            display: flex;
            flex-wrap: wrap;
        }
        > dt {
            width: 100px;
            background-color: $yellowOrange;
            padding: 5px 0 5px 10px;
            font-weight: $font-bold;
            border-bottom-right-radius: 20px;
            box-sizing: border-box;
            @include pc {
                width: 120px;
                border-radius: 0;
                border-bottom: 2px solid $white;
                padding-left: 20px;
                &:last-of-type {
                    border-bottom: none;
                }
            }
        }
        > dd {
            border-bottom: 2px solid $yellowOrange;
            padding: 5px 10px 5px;
            box-sizing: border-box;
            &:last-of-type {
                border-bottom: none;
                padding-bottom: 10px;
                @include pc {
                    padding-bottom: 0;
                }
            }
            @include pc {
                width: calc(100% - 120px);
            }
        }
    }

    &__interviewee {
        border: 2px solid $yellowOrange;
        border-radius: 20px;
        overflow: hidden;
        margin: 20px 20px 0;
        @include pc {
            width: calc(50% - 15px);
            margin: 0;
        }
        & > h3 {
            width: 130px;
            background-color: $yellowOrange;
            padding: 5px 0 5px 10px;
            font-weight: $font-bold;
            border-bottom-right-radius: 20px;
            @include pc {
                display: inline-block;
                padding: 5px 20px;
                width: auto;
            }
        }
        & > dl {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 10px;
            margin-top: 5px;
            & > dt {
                font-weight: $font-bold;
                width: 30%;
                padding: 0 10px;
                box-sizing: border-box;
                @include pc {
                    padding-left: 20px;
                }
            }
            & > dd {
                width: 70%;
                padding-right: 10px;
                box-sizing: border-box;
                @include pc {
                    padding-right: 20px;
                }
            }
        }
    }

    &__name--coral {
        color: #FF4F4F;
    }
    &__name--blue {
        color: #4978DC;
    }
    &__name--green {
        color: #13CC2C;
    }
    &__name--purple {
        color: #B642FF;
    }

    &__content {
        margin: 30px 20px;
        @include pc {
            width: 700px;
            margin: 30px auto;
        }
        & > h2 {
            font-size: 1.8rem;
            line-height: 2.6rem;
            margin: 20px 0;
            font-weight: $font-bold;
            @include pc {
                font-size: 2.6rem;
                line-height: 3.6rem;
            }
            &.p-article__line {
                margin-top: 60px;
                position: relative;
                @include pc {
                    margin-top: 80px;
                }
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 190px;
                    height: 5px;
                    background: url(/business/assets/img/line_step_flat.png) 0 no-repeat;
                    background-size: auto 5px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    top: -30px;
                    @include pc {
                        width: 500px;
                        top: -40px;
                    }
                }
            }
        }
        & > p {
            margin-bottom: 20px;
            &.p-article__interviwerComment {
                font-weight: $font-bold;
                &:before {
                    content: 'ー';
                    display: inline;
                    margin-right: 10px;
                }
            }
            & > strong {
                border-bottom: 2px solid $yellowOrange;
                @include pc {
                    border-bottom: 3px solid $yellowOrange;
                }
            }
        }
        & > figure {
            margin: 30px 0;
            & > img {
                width: 100%;
            }
            & > figcaption {
                display: flex;
                justify-content: center;
                font-size: 1.2rem;
                line-height: 1.8rem;
                margin-top: 10px;
                @include pc {
                    font-size: 1.4rem;
                    line-height: 2.0rem;
                }
            }
        }
        .p-article__name {
            margin-bottom: 0;
            font-weight: $font-bold;
        }
    }

    &__summary {
        border: 2px solid $yellowOrange;
        border-radius: 20px;
        margin: 20px 0 40px;
        padding: 20px 20px;
    }
    &__contact {
        margin: 40px 20px 70px;
        text-align: center;
        @include pc {
            margin-bottom: 80px;
        }
    }
    &__contactText {
        font-size: 1.6rem;
        font-weight: $font-bold;
        margin-bottom: 20px;
        @include pc {
            font-size: 2.0rem;
            line-height: 3.0rem;
        }
    }
    &__contactLink {
        display: inline-block;
        padding-left: 14px;
        margin-top: 20px;
        font-weight: $font-bold;
        position: relative;
        font-size: 1.6rem;
        @include pc {
            font-size: 2.0rem;
        }
        &::before {
            content: "";
            display: block;
            width: 8px;
            height: 10px;
            left: 0;
            top: 50%;
            margin-top: -5px;
            position: absolute;
            background: url(/business/assets/img/ic_link_triangle.png) 50% no-repeat;
            background-size: contain;
        }
    }

    &__what {
        background-color: $white;
        border-radius: 20px;
        padding: 15px 20px;
        margin: 0 10px 15px;
        overflow: hidden;
        @include pc {
            width: 950px;
            margin: 0 auto 20px;
            padding: 30px;
            box-sizing: border-box;
            background: $white url(/business/assets/img/ill_what_article.png) no-repeat right 30px center;
            background-size: 274px;
        }

        &Title {
            width: 130px;
            padding-bottom: 4px;
            border-bottom: 4px solid $yellowOrange;
            margin-bottom: 10px;
            @include pc {
                width: 150px;
            }

            img {
                width: 100%;
            }
        }

        &Paragraph {
            font-size: 1.2rem;
            line-height: 1.6em;
            @include pc {
                font-size: 1.6rem;
                line-height: 1.6em;
                width: 580px;
            }
        }

        &ImgParagraph {
            font-size: 1.2rem;
            line-height: 1.6em;
            min-height: 80px;
            background: url(/business/assets/img/ill_what_article.png) no-repeat right bottom;
            background-size: 136px;
            @include pc {
                font-size: 1.6rem;
                line-height: 1.6em;
                background: none;
                min-height: auto;
                width: 580px;
            }

            &:before {
                content: "";
                display: block;
                width: 136px;
                height: 80px;
                float: right;
                @include pc {
                    display: none;
                }
            }
        }
    }
}
