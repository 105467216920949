footer {
  .wrap-box {
    width: 90%;
    max-width: 900px;
    padding: 20px 0;
    margin: 0 auto;
    align-items: center;
    @include pc {
      display: flex;
      justify-content: space-between;
    }
  }
  .foot_left {
    img {
        width: 100px;
        vertical-align: baseline;
        + span {
        font-size: 10px;
        }
    }
    a {
        display: block;
        color: #555;
    }
  }
  ul {
    margin: 30px 0;
    @include pc {
        margin: auto 0 auto auto;
    }
    li {
        display: block;
        margin: 4px 0;
        @include pc {
            display: inline-block;
            margin: 0 10px;
        }
    }
    a {
      color: #3078BE;
      font-size: 12px;
      font-weight: normal;
      text-decoration: underline;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}