// break point
$pc: 680px;

// color
$white: #FFFFFF;
$black: #222222;
$yellowOrange: #FFA400;
$orange: #FF7300;
$lightYellow: #FFEA87;
$lightGray: #EEF3F5;
$gray: #C8C0B7;
$darkGray: #898581;
$blueGray: #DFE6E5;
$emerald: #19C5B7;

// font-weight
$font-bold: 600;

// hover
$hover: .5;
$duration: 300ms;