html {
    font-size: 10px;
    scroll-behavior: smooth;
}

body {
    background-color: $white;
    font-family: 'Hiragino Kaku Gothic ProN','メイリオ', sans-serif;
    font-size: 1.4rem;
    line-height: 1.8em;
    color: $black;
    @include pc {
        font-size: 1.6rem;
    }
}

a {
    text-decoration: none;
    color: $emerald;
    @include pc {
        transition: opacity 300ms 0s ease;
        &:hover {
            opacity: .7;
        }
    }
}