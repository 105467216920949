.p-help {
    line-height: 1.5;

    &__header {
        position: relative;
        display: flex;
        justify-content: space-between;
        @include pc {
            display: flex;
            width: 950px;
            margin: 0 auto;
            left: 0;
            right: 0;
        }

        &Title {
            @include pc {
                display: flex;
                justify-content: space-between;
            }

            h1,
            h2 {
                background-color: $white;
                display: inline-block;
                margin: 0 20px;
                padding: 8px;
                border-radius: 0 0 10px 10px;

                @include pc {
                    padding: 12px 13px;
                    margin: 0;
                }

                img {
                    width: 78px;

                    @include pc {
                        width: 110px;
                    }
                }
            }
        }

        &Btn {
            margin-top: 10px;
            margin-right: 10px;
            z-index: 10;
            width: 90px;

            @include pc {
                width: 140px;
                height: 50px;
                margin-right:0;
            }

            .p-lp__secondaryBtn {
                height: 40px;
                margin-bottom: 0;

                @include pc {
                    height: 50px;
                }
            }
        }
    }

    &__main {
        padding: 30px 20px 30px;
        
        @include pc {
            padding: 40px 0 30px;
            width: 950px;
            margin: auto;
        }

        &Title {
            font-size: 2.4rem;
            text-align: center;
            font-weight: bold;
    
            @include pc {
                font-size: 3.0rem;
            }
        }
    }

    &__outline {
        margin: 30px 0;
        padding: 20px;
        background-color: $lightGray;
        border-radius: 20px;
        font-size: 1.6rem;

        &Title {
            font-weight: bold;
            margin-bottom: 10px;
        }
        
        &List {
            list-style-type: auto;
            padding-left: 24px;
        }

        &Link {
            text-decoration: underline;
        }
    }

    &__help {

        &Title {
            font-weight: bold;
            font-size: 2.0rem;
            margin: 30px 0 20px;
            padding-top: 10px;

            @include pc {
                font-size: 2.4rem;
                margin: 40px 0 20px;
            }
        }

        &List {
            background-color: $white;
            border: 4px solid $lightGray;
            padding: 20px;
            border-radius: 20px;
            margin-bottom: 20px;
        }

        &Question {
            font-size: 1.8rem;
            font-weight: bold;
            color: $orange;
            margin-bottom: 10px;
        }

        &Answer {
            font-size: 1.6rem;
        }
    }

    &__planImg {
        margin-top: 10px;

        @include pc() {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }

        &Item {
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }

            @include pc() {
                width: calc(50% - 10px);
                margin-bottom: 20px;
                &:nth-last-of-type(2) {
                    margin-bottom: 0;
                }
            }

            &>img {
                width: 100%;
            }
        }
    }
}