.u-none--sp {
    display: none;
    @include pc {
        display: inherit;
    }
}
.u-none--pc {
    display: inherit;
    @include pc {
        display: none;
    }
}
.u-none {
    display: none;
}