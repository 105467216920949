.p-lp {
    &__sectionTtl {
        text-align: center;
        margin-bottom: 20px;
        @include pc {
            margin-bottom: 30px;
        }
        & > img {
            height: 20px;
            @include pc {
                height: 40px;
            }
        }
        &2line {
            & > img {
                height: 52px;
                @include pc {
                    height: 40px;
                }
            }
        }
        &3line {
            & > img {
                height: 82px;
                @include pc {
                    height: 40px;
                }
            }
        }
    }
    &__primaryBtn {
        display: flex;
        cursor: pointer;
        background: $orange;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.10);
        border-radius: 4px;
        font-size: 2.0rem;
        font-weight: $font-bold;
        color: $white;
        text-align: center;
        width: 100%;
        height: 80px;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        @include pc {
            font-size: 2.4rem;
            max-width: 500px;
            height: 70px;
        }
        &--ab01 {
            width: calc(100% - 40px);
            border: 4px solid #fff;
            box-sizing: border-box;
            @include pc {
                width: 500px;
                height: 70px;
                margin-left: -250px;
                position: absolute;
                bottom: 40px;
                left: 50%;
            }
        }
    }
    &__secondaryBtn {
        display: flex;
        cursor: pointer;
        background: $white;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.10);
        border: solid 1px $gray;
        border-radius: 4px;
        font-size: 1.6rem;
        font-weight: $font-bold;
        color: $orange;
        text-align: center;
        width: 100%;
        height: 50px;
        justify-content: center;
        align-items: center;
        margin: 0 0 10px;
        @include pc {
            font-size: 1.6rem;
            max-width: 300px;
            height: 50px;
        }
    }
    &__tertiaryBtn {
        display: flex;
        cursor: pointer;
        background: $white;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.10);
        border: solid 1px $gray;
        border-radius: 4px;
        font-size: 1.4rem;
        font-weight: $font-bold;
        color: $emerald;
        text-align: center;
        width: 100%;
        min-height: 40px;
        padding: 5px 0;
        justify-content: center;
        align-items: center;
        margin: 0 auto 10px;
        max-width: 250px;
        box-sizing: border-box;
    }
    &__titleArea {
        position: absolute;
        z-index: 100;
        @include pc {
            width: 950px;
            margin: 0 auto;
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;
        }
        h1, h2 {
            background-color: $white;
            display: inline-block;
            margin: 0 20px;
            padding: 8px;
            border-radius: 0 0 10px 10px;
            @include pc {
                padding: 12px 13px;
                margin: 0;
            }
            img {
                width: 78px;
                @include pc {
                    width: 110px;
                }
            }
        }
    }
    &__fixedNav {
        position: fixed;
        top: 10px;
        right: 10px;
        z-index: 10;
        display: flex;
        @include pc {
            right: auto;
            position: relative;
            display: block;
        }
        .p-lp__primaryBtn {
            width: 90px;
            height: 40px;
            font-size: 1.4rem;
            margin: 0 0 0 5px;
            @include pc {
                font-size: 2.0rem;
                width: 200px;
                height: 40px;
                position: fixed;
                margin-left: -200px;
            }
        }

        .p-lp__secondaryBtn {
            width: 90px;
            height: 40px;
            font-size: 1.4rem;
            @include pc {
                font-size: 2.0rem;
                width: 200px;
                height: 40px;
                position: fixed;
                margin-left: -200px;
            }
        }
    }
    &__header {
        &.p-lp__header--only {
            padding-bottom: 10px;
        }

        .p-lp__fv--slide {
            padding-top: 50px;
            @include pc {
                display: flex;
                width: 950px;
                height: 400px;
                margin: 0 auto;
                padding-top: 70px;
            }
            h2 {
                margin: 22px 20px 16px;
                text-align: center;
                @include pc {
                    margin: 27px 0 0;
                    text-align: left;
                }
                .p-lp__fvimg--estate {
                    width: 264px;
                    @include pc {
                        width: 455px;
                    }
                }
                .p-lp__fvimg--parking {
                    width: 270px;
                    @include pc {
                        width: 435px;
                        padding-right: 20px;
                    }
                }
                img {
                    width: 214px;
                    @include pc {
                        width: 416px;
                    }
                }
            }
            .p-lp__capture {
                margin: 20px 20px 0;
                background-color: $black;
                padding: 20px;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
                @include pc {
                    margin: 4px 0 0 24px;
                    padding: 30px;
                }

                img {
                    width: 100%;
                }
            }
        }
        &.p-lp__header--only {
            padding-bottom: 10px;
        }

        &--businessExpansion {
            background: #C2E2FF;
        }

        &--parking {
            .p-lp__fv--slide {
                @include pc {
                    height: 370px;
                }
            }
        }

        &--ab01 {
            background: $yellowOrange url(/business/assets/img/kv_ab01_bg_sp.svg) no-repeat center bottom;
            background-size: 438px auto;
            @include pc {
                background: $yellowOrange url(/business/assets/img/kv_ab01_bg_pc.svg) no-repeat center bottom;
                background-size: 2586px auto;
            }
            .p-lp__fv {
                padding: 50px 0 46px;
                position: relative;
                @include pc {
                    padding: 0;
                }
            }
        }
    }
    &__fv {
        padding-top: 50px;
        @include pc {
            display: flex;
            width: 950px;
            margin: 0 auto;
            padding-top: 70px;
        }
        h2 {
            margin: 22px 20px 16px;
            text-align: center;
            @include pc {
                margin: 27px 0 0;
                text-align: left;
            }
            .p-lp__fvimg--estate {
                width: 264px;
                @include pc {
                    width: 455px;
                }
            }
            img {
                width: 214px;
                @include pc {
                    width: 416px;
                }
            }
        }
        .p-lp__capture {
            margin: 20px 20px 0;
            background-color: $black;
            padding: 20px;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            @include pc {
                margin: 4px 0 0 36px;
                padding: 30px;
            }
            > img {
                width: 100%;
            }
        }
        &--single {
            padding-top: 0;

            @include pc {
                padding-top: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
            }

            img {
                width: 100%;
            }
        }
    }
    &__headerBg {
        background-color: $yellowOrange;
    }
    &__articleSlide {
        width: 100%;
        height: 100%;
        overflow: hidden;

        & > a {
            display: block;
            width: 100%;
            height: 100%;
            & > div {
                width: 100%;
                height: 100%;
            }
        }
        & > div {
            width: 100%;
            height: 100%;
        }
        &Bg {
            overflow: hidden;
            width: calc(100% + 100px);
            position: absolute;
            filter: blur(20px);
            margin: auto;
            left: -50px;
            top: -40px;
            z-index: 1;
            img {
                width: 100%;
                opacity: .5;
            }
        }
        &Content {
            position: relative;
            height: 100%;
            width: 100%;
            z-index: 2;
            margin: auto;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 100%;
            }
        }
    }
    &__what {
        background: url(/business/assets/img/ill_what.png) bottom right no-repeat;
        background-size: auto 166px;
        padding: 0 20px 170px;
        margin: 30px 0 50px;
        @include pc {
            background: url(/business/assets/img/ill_what.png) bottom center no-repeat;
            background-size: 950px auto;
            width: 950px;
            margin: 50px auto 50px;
            padding: 0 0 30px;
        }
        &--mansion {
            background: url(/business/assets/img/ill_what_mansion.png) bottom right no-repeat;
            background-size: auto 166px;
            @include pc {
                background: url(/business/assets/img/ill_what_mansion.png) bottom center no-repeat;
                background-size: 950px auto;
            }
        }
        > p {
            @include pc {
                width: 500px;
            }
        }
        .p-lp__whatLink {
            display: inline-block;
            margin: 20px 0;
            padding-left: 14px;
            font-weight: $font-bold;
            position: relative;
            @include pc {
                margin: 10px 0 20px;
            }
            &:before {
                content: '';
                display: block;
                width: 8px;
                height: 10px;
                left: 0;
                top: 50%;
                margin-top: -5px;
                position: absolute;
                background: url(/business/assets/img/ic_link_triangle.png) center center no-repeat;
                background-size: contain;
            }
        }
    }
    &__example {
        margin: 50px 20px 50px;
        &List {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            position: relative;
            @include pc {
                width: 750px;
                margin: 0 auto;
                justify-content: space-between;
            }
            > li {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 136px;
                height: 136px;
                color: $white;
                font-weight: $font-bold;
                text-align: center;
                margin-bottom: 12px;
                position: relative;
                z-index: 1;
                border-radius: 50%;
                @include pc {
                    width: 223px;
                    height: 223px;
                    font-size: 2.4rem;
                    line-height: 1.6em;
                    margin: 0;
                }
                &:before {
                    content: '';
                    display: block;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top:0;
                    left: 0;
                    right: 0;
                    z-index: -1;
                    border-radius: 50%;
                }
                &:nth-child(1):before {
                    background-image: url(/business/assets/img/ph_example_01.jpg);
                }
                &:nth-child(2):before {
                    background-image: url(/business/assets/img/ph_example_02.jpg);
                }
                &:nth-child(3):before {
                    background-image: url(/business/assets/img/ph_example_03.jpg);
                }
            }
        }
        &Text {
            text-align: center;
            font-size: 1.6rem;
            font-weight: $font-bold;
            margin: 30px 0 20px;
            @include pc {
                font-size: 2.0rem;
                margin: 40px 0 20px;
            }
        }
    }
    &__price {
        margin: 50px 20px 50px;
        @include pc {
            width: 950px;
            margin: 50px auto;
            position: relative;
        }
        .p-lp__sectionTtl {
            > img {
                height: 50px;
                @include pc {
                    height: 100px;
                }
            }
        }
        &List {
            @include pc {
                display: flex;
                justify-content: space-between;
            }

            &--bussinessExpansion {
                .p-lp__priceItem {
                    overflow: inherit;
                    margin-bottom: 196px;

                    @include pc {
                        margin-bottom: 230px;
                    }
                }
            }
        }

        &Item {
            border: solid 3px #FFA400;
            border-radius: 14px;
            width: 100%;
            margin-bottom: 20px;
            overflow: hidden;
            position: relative;
            box-sizing: border-box;

            @include pc {
                margin-bottom: 0;
                width: calc(50% - 5px);
                padding-bottom: 60px;
            }

            .p-lp__tertiaryBtn {
                margin-bottom: 20px;
                width: calc(100% - 20px);
                max-width: none;

                @include pc {
                    width: calc(100% - 40px);
                    position: absolute;
                    bottom: 20px;
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }

        &Title {
            background-color: $yellowOrange;
            font-size: 1.8rem;
            font-weight: $font-bold;
            padding: 15px;
            @include pc {
                font-size: 2.2rem;
            }
        }

        &Overview {
            padding: 10px 10px 0;
            @include pc {
                display: flex;
                justify-content: space-between;
                padding: 20px 20px 0;
            }
        }

        &Price {
            font-weight: bold;
        }

        &Img {
            text-align: center;
            margin: 10px 0;
            display: flex;
            justify-content: center;
            flex-direction: row-reverse;
            @include pc {
                text-align: left;
                margin: 0;
                margin-left: -15px;
            }
            & >img {
                position: relative;
                z-index: 2;
                width: 80px;
                &:nth-of-type(2) {
                    right: -10px;
                    z-index: 1;
                }
            }
        }

        &Monthly {
            font-size: 1.2rem;
            margin-bottom: 10px;
            @include pc {
                font-size: 1.4rem;
            }
            & > strong {
                font-size: 2.6rem;

                @include pc {
                    font-size: 3.0rem;
                }
            }
        }

        &Shot {
            font-size: 1.4rem;
            & > span {
                color: $orange;
                font-size: 2.2rem;
                vertical-align: baseline;
            }
        }

        &Text {
            text-align: center;
            font-size: 1.6rem;
            font-weight: $font-bold;
            margin: 30px 0 20px;
            @include pc {
                font-size: 2.0rem;
                margin: 40px 0 20px;
            }
        }

        &Cut {
            position: absolute;
            left: -3px;
            width: calc(100% + 6px);
            bottom: -176px;

            @include pc {
                bottom: -220px;
            }

            &Text {
                background: $lightGray;
                border-radius: 14px;
                font-weight: bold;
                text-align: center;
                width: 100%;
                line-height: 1.4;
                font-size: 1.8rem;
                padding: 20px 0;
                position: relative;

                @include pc {
                    font-size: 2rem;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 26px;
                    height: 25.5px;
                    top: -36px;
                    left: 50%;
                    margin-left: -13px;
                    background: url(/business/assets/img/ic_arrow_2colors.png);
                    background-size: contain;

                    @include pc {
                        width: 52px;
                        height: 51px;
                        top: -66px;
                        margin-left: -26px;
                    }
                }

                strong {
                    color: $orange;
                    font-size: 2.4rem;

                    @include pc {
                        font-size: 2.6rem;
                    }
                }

                span {
                    @include pc {
                        font-size: 1.2rem;
                    }
                }
            }

            &Notes {
                font-size: 1.2rem;
                text-align: right;
                margin-top: 4px;

                @include pc {
                    font-size: 1.4rem;
                }
            }
        }
    }
    &__possible {
        &Title {
            font-size: 1.4rem;
            padding: 10px 20px;
            border-radius: 14px 14px 0 0;
            background-color: $lightYellow;
            display: inline-block;
            font-weight: bold;
            margin: 0 10px;

            @include pc {
                font-size: 1.6rem;
                margin: 0 20px;
            }
        }
        &Wrapper {
            border: solid 2px $lightYellow;
            margin: 0 10px 20px;
            border-radius: 0 14px 14px 14px;
            padding: 10px;
            overflow: hidden;
            transition: max-height 1s;
            max-height: 4000px;

            @include pc {
                margin: 0 20px 20px;
            }

            &--close {
                max-height: 180px;
                position: relative;
                cursor: pointer;

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 40px;
                    left: 0;
                    width: 100%;
                    height: 40px;
                    background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 0%, #fff 100%);
                    z-index: 1;
                }
                &::after {
                    content: "＋ すべて見る";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    margin: auto;
                    text-align: center;
                    color: $emerald;
                    font-size: 1.4rem;
                    font-weight: bold;
                    z-index: 2;
                    background-color: $white;
                    height: 40px;
                }
                
                @include pc {
                    max-height: none;
                    cursor: default;

                    &::before {
                        content: none;
                    }
                    &::after {
                        content: none;
                    }
                }
            }
        }
        &List {
            border: solid 2px $lightGray;
            border-radius: 10px;
        }
        &Table {
            width: 100%;
            border-collapse: collapse;
            overflow: hidden;

            & > thead th {
                background-color: $lightGray;
                font-size: 1.2rem;
                border-left: 1px solid $gray;
                text-align: center;
                font-weight: bold;
                line-height: 1.2;
                padding: 2px 0;
                @include pc {
                    font-size: 1.6rem;
                    padding: 10px 0;
                }
            }

            & > thead th:first-of-type {
                border-left: none;
            }

            & > tbody tr {
                font-size: 1.2rem;
                line-height: 2.4rem;
            }

            & > tbody tr:nth-of-type(2n) {
                background-color: $lightGray;
            }

            & > tbody th {
                width: 100px;
                padding-left: 10px;
                text-align: left;

                @include pc {
                    width: 130px;
                }
            }

            & > tbody td {
                border-left: 1px solid $gray;
            }
        }
        &Ok {
            background: url(/business/assets/img/ic_circle_sp.png) no-repeat center center;
            background-size: 14px;
        }
        &Notice {
            margin-top: 10px;
            font-size: 1.2rem;
            line-height: 1.6;
        }
    }
    &__impossible {
        &Title {
            font-size: 1.4rem;
            padding: 10px 20px;
            border-radius: 14px 14px 0 0;
            background-color: $blueGray;
            display: inline-block;
            font-weight: bold;
            margin-top: 20px;

            @include pc {
                font-size: 1.6rem;
            }
        }
        &Wrapper {
            border: solid 2px $blueGray;
            margin: 0 auto 20px;
            border-radius: 0 14px 14px 14px;
            padding: 10px;

            @include pc {
            }
        }
        &List {
            list-style-type: disc;
            margin-left: 30px;
        }
    }
    &__costPerformance {
        background-color: $blueGray;
        padding: 30px 20px 50px;
        &List {
            font-size: 1.4rem;

            @include pc {
                display: flex;
                width: 950px;
                margin: 0 auto;
            }
            & > li {
                background-color: $white;
                padding: 20px 10px 30px;
                border-radius: 20px;
                margin-bottom: 20px;

                @include pc {
                    margin: 0 20px 0 0;
                    padding: 20px 15px;
                    width: 33.3%;
                }

                &:last-of-type {
                    margin-bottom: 0;

                    @include pc {
                        margin-right: 0;
                    }
                }

                figure {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 130px;
                }
            }

            strong {
                font-weight: $font-bold;
            }
        }

        &Title {
            text-align: center;
            font-weight: $font-bold;
            font-size: 2.0rem;
        }

        &Next {
            color: $yellowOrange;
            padding: 40px 0 10px;
            text-align: center;
            font-weight: $font-bold;
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 0;
                height: 0;
                margin: auto;
                left: 0;
                right: 0;
                top: 15px;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-top: 16px solid $yellowOrange;
            }
        }
    }
    &__worries {
        background-color: $yellowOrange;
        padding: 30px 20px 30px;
        @include pc {
            padding: 50px 0 40px;
        }
        > div {
            @include pc {
                width: 950px;
                margin: 0 auto;
            }
        }
        .p-lp__sectionTtl {
            margin: 0 0 20px;
        }
        .p-lp__worriesList {
            > li {
                margin: 70px 0;
                position: relative;
                @include pc {
                    margin: 10px 0;
                }
                &:first-child {
                    margin-top: 90px;
                    @include pc {
                        margin-top: 30px;
                    }
                }
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    top: -76px;
                    left: 0;
                    @include pc {
                        width: 106px;
                        height: 106px;
                        top: 0;
                    }
                }
                &:nth-child(odd):before {
                    background: url(/business/assets/img/ic_question_01.png);
                    background-size: contain;
                }
                &:nth-child(even):before {
                    background: url(/business/assets/img/ic_question_02.png);
                    background-size: contain;
                }
                > dl {
                    position: relative;
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        left: -2px;
                        top: -1px;
                        background-color: $yellowOrange;
                        height: 8px;
                        width: 14px;
                        z-index: 3;
                        @include pc {
                            left: 136px;
                            height: 12px;
                            width: 26px;
                        }
                    }
                    > dt {
                        border: solid 3px $white;
                        border-radius: 0 20px 20px 20px;
                        background-color: $yellowOrange;
                        font-size: 1.5rem;
                        font-weight: $font-bold;
                        padding: 14px;
                        margin-right: 14px;
                        position: relative;
                        z-index: 2;
                        line-height: 1.6em;
                        box-sizing: border-box;
                        @include pc {
                            width: 616px;
                            padding: 28px;
                            margin: 0 0 0 136px;
                            font-size: 2.0rem;
                        }
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 0;
                            height: 0;
                            top: -20px;
                            left: 20px;
                            border: 8px solid transparent;
                            border-bottom: 12px solid $white;
                            z-index: 3;
                            @include pc {
                                top: 40px;
                                left: -28px;
                                border-style: solid;
                                border-color: $white transparent transparent transparent;
                                border-width: 24px 0 0 28px;
                            }
                        }
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 0;
                            height: 0;
                            top: -13px;
                            left: 22px;
                            border: 6px solid transparent;
                            border-bottom: 10px solid $yellowOrange;
                            z-index: 4;
                            @include pc {
                                top: 43px;
                                left: -20px;
                                border-color: #ffa400 transparent transparent;
                                border-style: solid;
                                border-width: 22px 0 0 26px;
                            }
                        }
                    }
                    > dd {
                        border-radius: 20px;
                        background-color: $white;
                        padding: 38px 14px 14px;
                        margin-left: 18px;
                        position: relative;
                        z-index: 1;
                        top: -24px;
                        line-height: 1.6em;
                        box-sizing: border-box;
                        @include pc {
                            width: 616px;
                            padding: 50px 28px 28px;
                            margin: 0 0 0 192px;
                        }
                        &:before {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 0;
                            height: 0;
                            bottom: -20px;
                            right: 20px;
                            border: 8px solid transparent;
                            border-top: 12px solid $white;
                            z-index: 3;
                            @include pc {
                                top: 40px;
                                right: -28px;
                                border-style: solid;
                                border-color: transparent transparent transparent $white;
                                border-width: 0px 0px 24px 28px;
                            }
                        }
                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 60px;
                            height: 60px;
                            bottom: -80px;
                            right: -3px;
                            background: url(/business/assets/img/ic_answer.png);
                            background-size: contain;
                            @include pc {
                                width: 106px;
                                height: 106px;
                                right: -142px;
                                bottom: auto;
                                top: 0;
                            }
                        }
                        > h4 {
                            font-size: 1.5rem;
                            font-weight: $font-bold;
                            margin-bottom: 6px;
                            @include pc {
                                font-size: 2.2rem;
                            }
                        }
                    }
                }
            }
        }
    }
    &__step {
        margin: 30px 20px 50px;
        @include pc {
            margin: 50px 0 54px;
        }

        &Btn {
            @include pc {
                width: 400px;
            }
        }

        &List {
            @include pc {
                width: 950px;
                margin: 0 auto;
            }
            > li {
                position: relative;
                padding: 0 0 20px;
                margin: 40px 0;
                @include pc {
                    margin: 40px 0 50px;
                    min-height: 120px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-start;
                }
                &:first-child {
                    margin-top: 30px;
                }
                &:last-child {
                    margin-bottom: 20px;
                }
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 46px;
                    height: 35px;
                    background-position: left center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    left: 0;
                    top: 0;
                }
                &:nth-child(1):before {
                    background-image: url(/business/assets/img/step_01.svg);
                    @include pc {
                        width: 94px;
                        height: 80px;
                    }
                }
                &:nth-child(2):before {
                    background-image: url(/business/assets/img/step_02.svg);
                    @include pc {
                        width: 107px;
                        height: 79px;
                    }
                }
                &:nth-child(3):before {
                    background-image: url(/business/assets/img/step_03.svg);
                    @include pc {
                        width: 107px;
                        height: 79px;
                    }
                }
                &:nth-child(4):before {
                    background-image: url(/business/assets/img/step_04.svg);
                    @include pc {
                        width: 110px;
                        height: 79px;
                    }
                }
                &:nth-child(5):before {
                    background-image: url(/business/assets/img/step_05.svg);
                    @include pc {
                        width: 106px;
                        height: 79px;
                    }
                }
                &:nth-child(6):before {
                    background-image: url(/business/assets/img/step_06.svg);
                    @include pc {
                        width: 107px;
                        height: 81px;
                    }
                }
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 20px;
                    background: url(/business/assets/img/line_step_arrow.png) left center no-repeat;
                    background-size: auto 20px;
                    left: 0;
                    bottom: -20px;
                    @include pc {
                        height: 23px;
                        background-size: 950px 23px;
                    }
                }
                &:last-child:after {
                    height: 5px;
                    background: url(/business/assets/img/line_step_flat.png) left center no-repeat;
                    background-size: auto 4.3px;
                    bottom: 0;
                    @include pc {
                        height: 5px;
                        background-size: 950px 5px;
                    }
                }
                .p-lp__primaryBtn {
                    max-width: 400px;
                    height: 80px;
                    margin-top: 20px;
                }
                .p-lp__stepContent {
                    @include pc {
                        margin-right: 20px;
                        flex: 1;
                    }
                    > h4 {
                        margin: 0 0 16px 58px;
                        display: flex;
                        align-items: center;
                        height: 35px;
                        font-size: 1.8rem;
                        font-weight: $font-bold;
                        @include pc {
                            font-size: 2.0rem;
                            margin: 0 0 0 130px;
                        }
                    }
                    > p {
                        margin-bottom: 10px;
                        @include pc {
                            margin: 0 0 0 130px;
                        }
                        &.p-lp__stepMinParagraph {
                            font-size: 1.2rem;
                            line-height: 1.6em;
                            @include pc {
                                font-size: 1.4rem;
                                line-height: 1.6em;
                                margin-top: 10px;
                            }
                        }
                    }
                }
                .p-lp__stepNotice {
                    background-color: $lightGray;
                    border-radius: 20px;
                    padding: 20px;
                    margin-bottom: 10px;

                    @include pc {
                        margin: 10px 0 0 130px;
                        width: 360px;
                    }

                    & > h4 {
                        font-size: 1.6rem;
                        font-weight: $font-bold;
                    }
                }
            }
            img {
                max-width: 100%;
                @include pc {
                    width: 400px;
                    flex-shrink: 0;
                }
            }
        }
        .p-lp__point {
            background-color: $lightGray;
            margin: 20px 0;
            padding: 20px;
            border-radius: 20px;
            @include pc {
                margin: 20px 0 20px 130px;
                overflow: hidden;
                width: 100%;
                display: flex;
            }
            &Content {
                @include pc {
                    width: 360px;
                }
                .p-lp__pointLabel {
                    background-color: $yellowOrange;
                    padding: 0 6px;
                    color: $white;
                    font-weight: $font-bold;
                    display: inline-block;
                }
                h5 {
                    font-size: 1.5rem;
                    font-weight: $font-bold;
                    margin: 10px 0;
                    @include pc {
                        font-size: 1.8rem;
                    }
                    & + p {
                        margin-bottom: 10px;
                        @include pc {
                            font-size: 1.4rem;
                            line-height: 1.6em;
                        }
                    }
                }
            }
            &Img {
                @include pc {
                    float: right;
                }
                img {
                    @include pc {
                        width: 380px;
                    }
                }
            }
        }
    }
    &__review {
        background-color: $yellowOrange;
        padding: 30px 20px 50px;
        justify-content: space-between;
        @include pc {
            padding: 50px 0 30px;
        }
        > div {
            @include pc {
                width: 950px;
                margin: 0 auto;
            }
        }
        &List {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            > li {
                background-color: $white;
                border-radius: 20px;
                width: 100%;
                box-sizing: border-box;
                padding: 20px;
                margin-bottom: 30px;
                position: relative;
                @include pc {
                    width: 49%;
                    margin-bottom: 60px;
                    padding: 30px;
                }
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: -15px;
                    right: 40px;
                    border-style: solid;
                    border-color: $white transparent transparent transparent;
                    border-width: 15px 0 0 15px;
                    @include pc {
                        border-width: 30px 0 0 28px;
                        bottom: -30px;
                    }
                }
            }
        }
    }
    &__form {
        margin: 60px 20px 50px;
        @include pc {
            margin: 80px 0 80px;
        }
        &List {
            $this: &;
            @include pc {
                width: 600px;
                margin: 0 auto;
            }
            > li {
                margin-bottom: 20px;
            }
            input, textarea {
                appearance: none;
                font-size: 1.4rem;
                display: block;
                width: 100%;
                background: $white;
                border: 1px solid $darkGray;
                border-radius: 4px;
                font-size: 16px;
                color: $black;
                padding: 20px;
                box-sizing: border-box;
                margin-top: 6px;
                @include pc {
                    font-size: 1.6rem;
                }
                &::placeholder {
                    color: $gray;
                }
                &#{$this}Small {
                    width: 50%;
                }
            }
            textarea {
                height: 200px;
            }
            &Select {
                position: relative;
                select {
                    appearance: none;
                    font-size: 1.4rem;
                    display: block;
                    width: 100%;
                    background: $white;
                    border: 1px solid $darkGray;
                    border-radius: 4px;
                    font-size: 16px;
                    color: $black;
                    padding: 20px;
                    box-sizing: border-box;
                    margin-top: 6px;
                    @include pc {
                        font-size: 1.6rem;
                    }
                }
                &::after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 5px 0 5px;
                    border-color: $black transparent transparent transparent;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right:10px;
                    margin: auto;
                }
            }
            &--small {
                input, textarea, select {
                    padding: 10px;
                }
            }
            &Small {
                width: 50%;
            }
        }
        &Notice {
            font-size: 1.4rem;
            margin: 20px auto 10px;
            line-height: 1.6em;
            text-align: center;
            @include pc {
                width: 600px;
            }
        }
        &NoticeContent {
            font-size: 1.0rem;
            line-height: 1.6em;
            margin: 0 auto;
            @include pc {
                width: 600px;
            }
        }
        &Consent {
            font-size: 1.2rem;
            line-height: 1.6em;
            margin: 20px auto;
            @include pc {
                text-align: center;
                width: 600px;
            }
        }
        &Error {
            color: #FF4242;
            background-color: #FFF1F1;
            padding: 6px;
            margin-top: 10px;
            font-size: 1.2rem;
            @include pc {
                font-size: 1.4rem;
            }
        }
        &Title {
            text-align: center;
            font-size: 1.6rem;
            font-weight: $font-bold;
            margin: 30px 0 20px;
            @include pc {
                margin: 50px 0 20px;
                font-size: 2.0rem;
            }
        }
        &BothList {
            display: flex;
            justify-content: space-between;

            & {
                input {
                    width: calc(50% -10px);
                }
            }
        }
        .p-lp__submit {
            appearance: none;
            cursor: pointer;
            display: flex;
            background: $orange;
            box-shadow: 0 2px 10px 0 rgba(0,0,0,0.10);
            border-radius: 4px;
            font-size: 2.0rem;
            font-weight: $font-bold;
            color: $white;
            text-align: center;
            width: 100%;
            height: 60px;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            border: none;
            @include pc {
                font-size: 2.4rem;
                max-width: 374px;
                height: 70px;
                transition: opacity 300ms 0s ease;
                &:hover {
                    opacity: .7;
                }
            }
        }
    }
    &__verification {
        background-color: $lightGray;
        padding: 20px;
        > p {
            @include pc {
                width: 950px;
                margin: 0 auto;
            }
        }
    }
    &__finish {
        margin: 80px 20px 100px;
        padding: 0 20px 0;
        text-align: center;
        @include pc {
            margin: 100px auto 150px;
            padding: 0;
            width: 950px;
        }
        .p-lp__finishTitle {
            font-size: 2.2rem;
            margin-bottom: 40px;
            font-weight: $font-bold;
        }
    }
    &__articleList {
        flex-wrap: wrap;
        @include pc {
            display: flex;
            justify-content: space-between;
        }

        & > li {
            background-color: $white;
            border-radius: 20px;
            margin-bottom: 20px;
            overflow: hidden;
            box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
            transition: opacity 300ms 0s ease;
            &:hover {
                opacity: .7;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
            @include pc {
                width: calc(50% - 10px);
                margin-bottom: 20px;

                &:last-of-type {
                    margin-bottom: 20px;
                }
            }
            & > a {
                color: $black;
                & > div {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    h4 {
                        font-size: 1.6rem;
                        font-weight: $font-bold;
                        margin: 0 20px 0;
                        padding-top: 20px;
                        @include pc {
                            font-size: 2.0rem;
                            line-height: 3.2rem;
                        }
                        & > strong {
                            border-bottom: 2px solid $yellowOrange;
                            @include pc {
                                border-bottom: 3px solid $yellowOrange;
                            }
                        }
                    }
                    & > div {
                        margin-top: auto;
                        overflow: hidden;
                        p {
                            margin: 10px 20px;
                        }
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &__voiceList {
        margin-top: 30px;
        @include pc {
            width: 950px;
            margin: 20px auto 0;
        }

        & > li {
            display: flex;
            margin-bottom: 30px;
            @include pc {
                margin-bottom: 20px;
            }

            &:nth-child(2) {
                @include pc {
                    flex-direction: row-reverse;

                    & > div {

                        &:before {
                            @include pc {
                                top: 24px;
                                left: auto;
                                right: -34px;
                                border-width: 0 0 28px 34px;
                                border-color: transparent transparent transparent $yellowOrange;
                            }
                        }

                        &:after {
                            @include pc {
                                top: 28px;
                                left: auto;
                                right: -23px;
                                border-width: 0 0 28px 34px;
                                border-color: transparent transparent transparent $white;
                            }
                        }
                    }
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            & > picture {
                width: 60px;
                @include pc {
                    width: 120px;
                }

                & > img {
                    width: 100%;
                }
            }

            & > div {
                margin-left: 28px;
                background-color: $white;
                border: solid 2px $yellowOrange;
                border-radius: 20px;
                width: calc(100% - 88px);
                position: relative;
                font-size: 1.4rem;
                font-weight: $font-bold;
                padding: 10px;

                @include pc {
                    font-size: 2.0rem;
                    padding: 20px 30px;
                    border: solid 4px $yellowOrange;
                    width: auto;
                    display: inline-block;
                    margin: 20px 36px;
                }

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 24px;
                    left: -17px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0px 17px 14px 0;
                    border-color: transparent $yellowOrange transparent transparent;
                    z-index: 1;

                    @include pc {
                        top: 24px;
                        left: -34px;
                        border-width: 0px 34px 28px 0;
                    }
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 26px;
                    left: -12px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0px 17px 14px 0;
                    border-color: transparent $white transparent transparent;
                    z-index: 2;

                    @include pc {
                        top: 28px;
                        left: -23px;
                        border-width: 0px 34px 28px 0;
                    }
                }

                strong {
                    background-color: $lightYellow;
                    display: inline;
                    padding: 4px 6px;

                    @include pc {
                        padding: 6px 8px;
                    }
                }
            }
        }
    }
    &__modal {
        background-color: rgba(0, 0, 0, .4);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 200;
        flex-wrap: wrap;

        &Wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            margin: 30px 20px;
            @include pc {
                width: 900px;
            }
        }

        &Content {
            background-color: $white;
            padding: 20px 10px;
            width: 100%;
            max-height: calc(100% - 60px);
            border-radius: 20px;
            box-sizing: border-box;
            position: relative;
            overflow: auto;
            @include pc {
                padding: 30px;
            }
        }

        &Close {
            content: '';
            position: absolute;
            top: -16px;
            right: -16px;
            background: $black url('/business/assets/img/ic_cross.png') no-repeat center center;
            background-size: 18px;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            cursor: pointer;
            z-index: 201;
        }

        &Title {
            font-size: 1.6rem;
            font-weight: $font-bold;
            text-align: center;
            margin-bottom: 20px;

            @include pc {
                font-size: 2.0rem;
                margin-bottom: 30px;
            }
        }

        &Img {
            margin: 0 auto;
            display: block;
            width: 100%;
            @include pc {
                max-width: 500px;
            }
        }

        section + section {
            margin-top: 40px;
        }
    }
    &__businessModel {
        max-width: 400px;
        margin: 0 auto;
        @include pc {
            max-width: none;
            width: 500px;
        }

        img {
            width: 100%;
        }
    }
    &__bizmodel {
        max-width: 900px;
        margin: 0 auto 20px;
        padding: 40px 10px 0;

        @include pc {
            padding: 60px 0 0;
        }

        section + section {
            margin-top: 40px;
        }
    }
    &__qaList {
        & > dt {
            font-weight: bold;
            padding-top: 20px;

            &:first-of-type {
                padding-top: 0;
            }
        }

        & > dd {
            border-bottom: solid 1px $gray;
            padding-bottom: 20px;

            &:last-of-type {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }

    &__radio {
        width: 100%;
        margin-bottom: 10px;
        transition: opacity 300ms 0s ease;

        &:hover {
            opacity: .7;
        }

        @include pc {
            width: calc(50% - 5px);
        }

        & > input {
            display: none;

            & + label {
                display: flex;
                background-color: $white;
                border: solid 1px $blueGray;
                box-sizing: border-box;
                justify-content: center;
                align-items: center;
                font-size: 1.8rem;
                line-height: 1.4em;
                padding: 10px 20px 10px 48px;
                flex-wrap: wrap;
                cursor: pointer;
                border-radius: 4px;
                width: 100%;
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    left: 12px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    border-radius: 50%;
                    border: solid 1px $blueGray;
                }

                & > strong {
                    display: block;
                    width: 100%;
                }

                & > span {
                    display: block;
                    font-size: 1.4rem;
                    width: 100%;
                }
            }

            &:checked {
                & + label {
                    border: solid 2px $emerald;
                    padding: 9px 19px 9px 47px;

                    &:before {
                        border: solid 1px $emerald;
                        left: 11px;
                    }

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        left: 17px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        border-radius: 50%;
                        background-color: $emerald;
                    }
                }
            }
        }
    }

    &__quote {
        background-color: $lightGray;
        padding: 30px 20px 40px;

        @include pc {
            padding: 50px 0 60px;
        }

        h3 {
            text-align: center;
            margin-bottom: 30px;
        }

        h4 {
            font-size: 1.8rem;
            font-weight: $font-bold;

            @include pc {
                font-size: 2.0rem;
            }
        }

        &Wrapper {
            margin: 0 auto;

            @include pc {
                width: 600px;
            }
        }

        &RadioList {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 10px;
        }

        &Box {
            background-color: $white;
            padding: 20px;
            border-radius: 20px;
            margin-top: 20px;
            position: relative;
            display: none;

            &--checked {
                display: block;
            }

            .p-lp__quoteNotice {
                margin-bottom: 15px;

                @include pc {
                    margin-bottom: 20px;
                }
            }

            & > h4 {
                & > span {
                    font-size: 1.4rem;

                    @include pc {
                        font-size: 1.6rem;
                    }
                }
            }
        }

        &Notice {
            font-size: 1.4rem;
        }

        &List {
            margin: 15px 0 0;
            border-top: solid 1px $gray;
            @include pc {
                display: flex;
                flex-wrap: wrap;
            }

            & > dt {
                background-color: $lightGray;
                font-weight: $font-bold;
                display: inline-block;
                padding: 2px 10px;
                border-radius: 0 0 10px 10px;
                box-sizing: border-box;

                @include pc {
                    display: flex;
                    flex-basis: 30%;
                    max-width: 30%;
                    border-radius: 0;
                    border-bottom: solid 1px $gray;
                    align-items: center;
                    font-size: 1.4rem;
                }
            }

            & > dd {
                display: flex;
                align-items: center;
                padding: 10px 0 10px;
                border-bottom: solid 1px $gray;
                box-sizing: border-box;

                @include pc {
                    flex-basis: 70%;
                    max-width: 70%;
                    padding-left: 10px;
                    font-size: 1.4rem;
                }
            }
        }

        &Left {
            border-right: solid 4px $blueGray;
            padding-right: 10px;
            margin-right: 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            width: 100px;

            @include pc {
                width: 230px;
                justify-content: space-between;
            }
        }

        &Building {
            width: 100%;

            @include pc {
                width: 110px;
            }
        }

        &Multiplication {
            margin-right: 10px;
        }

        &Sum {
            text-align: right;
            font-size: 1.6rem;
            font-weight: $font-bold;
            flex: 1;
            word-break: break-all;
        }

        &Num {
            appearance: none;
            font-size: 1.4rem;
            width: 80px;
            max-width: 80px;
            background: $white;
            border: 1px solid $darkGray;
            border-radius: 4px;
            font-size: 16px;
            color: $black;
            padding: 5px 12px;
            box-sizing: border-box;
            &::placeholder {
                color: $gray;
                width: 100px;
            }
        }

        &Unit {
            @include pc {
                width: 70px;
                text-align: right;
            }
        }

        &Total {
            display: flex;
            border: solid 4px $blueGray;
            height: 90px;
            font-size: 1.6rem;
            box-sizing: border-box;
            justify-content: space-between;
            align-items: center;
            border-radius: 20px;
            padding: 20px;
            font-weight: $font-bold;
            background-color: $white;
            position: sticky;
            bottom: 20px;

            @include pc {
                font-size: 2.0rem;
                position: static;
            }
        }

        &Totalprice {
            color: $orange;
            font-size: 2.6rem;
            word-break: break-all;
            line-height: 1.4em;
            width: calc(100% - 100px);
            overflow: hidden;
            text-align: right;

            @include pc {
                font-size: 3.0rem;
                line-height: 1.4em;
                width: calc(100% - 120px);
            }
        }

        &Totaltitle {
            width: 100px;

            @include pc {
                width: 120px;
            }
        }

        .p-lp__form {
            margin: 0;
        }

        &--parking {
            .p-lp__radio>input+label {
                height: 72px;
            }
            .p-lp__quoteBuilding {
                width: 130px;
            }
            .p-lp__quoteLeft {
                width: 120px;
                @include pc {
                    width: auto;
                }
            }
        }
    }

    &__btnSecond {
        margin: 10px auto 0;

        @include pc {
            max-width: 400px;
        }
    }
    &__mechanism {
        margin: 30px 20px 40px;
        font-size: 1.4rem;
        line-height: 1.8em;
        @include pc {
            margin: 50px auto 70px;
            font-size: 1.6rem;
            line-height: 1.8em;
        }
        &MainTtl {
            & > img {
                height: 28px;
                @include pc {
                    height: 54px;
                }
            }
        }
        &Title {
            font-size: 1.6rem;
            line-height: 1.8em;
            font-weight: bold;
            @include pc {
                font-size: 2.0rem;
                line-height: 1.8em;
            }
        }
        &Wrap {
            @include pc {
                width: 950px;
                margin: 0 auto;
                display: flex;
            }
        }
        &Content {
            @include pc {
                width: 440px;
                margin-right: 30px;
            }
        }
        &Img {
            margin-top: 20px;
            text-align: center;
            @include pc {
                margin-top: 0;
                flex: 1;
            }
            & > img {
                max-width: 100%;
            }
            &Notice {
                font-weight: bold;
                text-align: left;
            }
        }
        &Graph {
            width: 200px;
            @include pc {
                width: 300px;
            }
        }
        &Notice {
            font-size: 1.2rem;
            line-height: 1.6em;
            margin-top: 10px;
        }
    }
    &__factor {
        background-color: $lightGray;
        padding: 30px 20px 40px;
        @include pc {
            padding: 50px 0 70px;
        }
        &Wrap {
            @include pc {
                display: flex;
                width: 750px;
                margin: 0 auto;
            }
        }
        &Speaker {
            display: flex;
            align-items: center;
            font-size: 1.6rem;
            font-weight: bold;
            @include pc {
                width: 160px;
                display: block;
                text-align: center;
                font-size: 1.2rem;
            }
            & > img {
                width: 80px;
                margin-right: 10px;
                @include pc {
                    width: 160px;
                }
            }
        }
        &Content {
            margin-top: 10px;
            font-size: 1.2rem;
            line-height: 1.8em;
            @include pc {
                margin: 0 0 0 20px;
                font-size: 1.4rem;
                line-height: 1.8em;
            }
            strong {
                border-bottom: solid 2px $yellowOrange;
            }
        }
        &Title {
            font-size: 1.6rem;
            line-height: 1.8em;
            font-weight: bold;
            margin-bottom: 10px;
            @include pc {
                font-size: 20px;
                line-height: 1.8em;
            }
        }
        &Closing {
            margin: 20px auto 20px;
            height: 65px;
            text-align: center;
            @include pc {
                margin: 40px auto 20px;
                height: 36px;
            }
        }
        &Tax {
            margin: 0 auto 20px;
            @include pc {
                width: 750px;
            }
        }
    }
    &__video {
        box-sizing: border-box;
        margin: 0 auto 60px;
        max-width: 720px;
        padding: 0 20px;
        width: 100%;
        @include pc {
            margin-bottom: 70px;
        }
        &Wrapper {
            width: 100%;
            height: 0;
            position: relative;
            padding-top: 56.25%;
            overflow: hidden;
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
    &__silver {
        margin: 50px 20px;
        @include pc {
            width: 950px;
            margin: 50px auto;
        }
        &Comparison {
            $this: &;
            overflow: hidden;
            &Label {
                display: none;
                @include pc {
                    display: block;
                    float: left;
                    width: 125px;
                    padding-top: 64px;
                }
            }
            &LabelText {
                @include pc {
                    background: $lightGray;
                    border-bottom: 2px solid $white;
                    text-align: center;
                    display: table;
                    width: 100%;
                    font-weight: bold;
                }
                span {
                    @include pc {
                        height: 100px;
                        display: table-cell;
                        vertical-align: middle;
                    }
                }
                &:first-child {
                    @include pc {
                        border-radius: 14px 0 0 0;
                    }
                }
                &:last-child {
                    @include pc {
                        border-bottom-color: $lightGray;
                        border-radius: 0 0 0 14px;
                    }
                }
            }
            &Heading {
                background: $lightYellow;
                text-align: center;
                font-weight: bold;
                font-size: 1.6rem;
                border-radius: 14px 14px 0 0;
                height: 46px;
                line-height: 46px;
                @include pc {
                    border-radius: 0 14px 0 0;
                }
            }
            &Detail {
                border: 2px solid $blueGray;
                border-top-width: 0;
                border-radius: 0 0 14px 14px;
                @include pc {
                    border-left-width: 0;
                    border-radius: 0 0 14px 0;
                }
            }
            &Box {
                @include pc {
                    border-bottom: 2px solid $blueGray;
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
            &SubHeading {
                background: $lightGray;
                font-size: 1.4rem;
                font-weight: bold;
                color: $darkGray;
                text-align: center;
                @include pc {
                    display: none;
                }
            }
            &Price {
                font-size: 1.4rem;
                text-align: center;
                padding: 0 15px;
                display: table;
                width: 100%;
                box-sizing: border-box;
                @include pc {
                    font-size: 1.6rem;
                }
                span {
                    display: table-cell;
                    vertical-align: middle;
                    height: 50px;
                    @include pc {
                        height: 100px;
                    }
                }
                strong {
                    font-size: 2.0rem;
                    @include pc {
                        font-size: 2.2rem;
                    }
                }
            }
            &Text {
                font-size: 1.4rem;
                padding: 8px 15px;
                display: table;
                width: 100%;
                box-sizing: border-box;
                @include pc {
                    font-size: 1.6rem;
                    padding: 0 45px;
                }
                span {
                    display: table-cell;
                    vertical-align: middle;
                    height: 50px;
                    position: relative;
                    padding-left: 38px;
                    background: url(/business/assets/img/ic_triangle_sp.png) left center no-repeat;
                    background-size: 28px 28px;
                    @include pc {
                        padding-left: 59px;
                    }
                    @include pc {
                        height: 100px;
                        background: url(/business/assets/img/ic_triangle_pc.png) left center no-repeat;
                        background-size: 44px 44px;
                    }
                }
            }
            &Col {
                @include pc {
                    width: 350px;
                    float: left;
                }
                &--erikura {
                    @include pc {
                        width: 475px;
                        float: left;
                    }
                    & + #{$this}Col {
                        margin-top: 20px;
                        @include pc {
                            margin-top: 18px;
                        }
                    }
                    #{$this}Heading {
                        background: $yellowOrange;
                        @include pc {
                            height: 64px;
                            line-height: 64px;
                            font-size: 24px;
                            border-radius: 14px 14px 0 0;
                        }
                    }
                    #{$this}Detail {
                        border-color: $yellowOrange;
                        @include pc {
                            border-left-width: 2px;
                            border-radius: 0;
                        }
                    }
                    #{$this}Price {
                        font-size: 1.6rem;
                        padding: 0 15px;
                        font-weight: bold;
                        @include pc {
                            font-size: 2.2rem;
                        }
                        span {
                            height: 60px;
                            @include pc {
                                height: 100px;
                            }
                        }
                        strong {
                            font-size: 3.0rem;
                            @include pc {
                                font-size: 4.0rem;
                            }
                        }
                    }
                    #{$this}Text {
                        font-size: 1.6rem;
                        font-weight: bold;
                        padding: 10px 15px;
                        @include pc {
                            font-size: 2.2rem;
                            line-height: 1.5;
                            padding: 0 20px;
                        }
                        span {
                            background: url(/business/assets/img/ic_circle_sp.png) left center no-repeat;
                            background-size: 28px 28px;
                            @include pc {
                                background: url(/business/assets/img/ic_circle_pc.png) left center no-repeat;
                                background-size: 44px 44px;
                            }
                        }
                        strong {
                            background: $lightYellow;
                            padding: 0 5px;
                            margin-right: 3px;
                            display: inline-block;
                        }
                    }
                }
            }
        }
        &--parking {
            .p-lp__silverComparison {
                margin-bottom: 10px;
            }
            .p-lp__silverComparisonText span {
                line-height: 1.5;
            }
            .p-lp__silverComparisonCol--erikura .p-lp__silverComparisonText {
                @include pc {
                    padding: 0 30px;
                }
            }
        }
    }
    &__comparison {
        $this: &;
        margin: 50px 20px;
        @include pc {
            width: 950px;
            margin: 50px auto;
        }
        &Table {
            @include pc {
                display: flex;
                position: relative;
            }
            &--ab01 {
                margin: 0 20px;
                @include pc {
                    width: 950px;
                    margin: 0 auto;
                }
                #{$this}LabelText {
                    background: #DFE6E5;
                }
            }
        }
        &Label {
            display: none;
            @include pc {
                display: block;
                width: 102px;
                padding-top: 64px;
            }
        }
        &LabelText {
            @include pc {
                background: $lightGray;
                border-bottom: 2px solid $white;
                text-align: center;
                width: 100%;
                font-weight: bold;

                &:first-child {
                    span {
                        height: 172px;
                    }
                }
            }
            span {
                @include pc {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    height: 100px;
                }
            }
            &:first-child {
                @include pc {
                    border-radius: 14px 0 0 0;
                }
            }
            &:last-child {
                @include pc {
                    border-bottom-color: $lightGray;
                    border-radius: 0 0 0 14px;
                }
            }
        }
        &Heading {
            background: $lightYellow;
            text-align: center;
            font-weight: bold;
            font-size: 1.6rem;
            border-radius: 14px 14px 0 0;
            height: 46px;
            line-height: 46px;
            @include pc {
                border-radius: 0;
                border-right: 2px solid $white;
            }
        }
        &Detail {
            border: 2px solid $blueGray;
            border-top-width: 0;
            border-radius: 0 0 14px 14px;
            @include pc {
                border-left-width: 0;
                border-radius: 0;
            }
        }
        &Box {
            background: $white;
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            &:last-child {
                border-radius: 0 0 12px 12px;
            }
            @include pc {
                height: 100px;
                border-bottom: 2px solid $blueGray;
                &:first-child {
                    height: 172px;
                }
                &:last-child {
                    border-bottom: none;
                    border-radius: 0;
                }
            }
        }
        &SubHeading {
            background: $lightGray;
            font-size: 1.4rem;
            font-weight: bold;
            color: $darkGray;
            text-align: center;
            width: 100%;
            @include pc {
                display: none;
            }
        }
        &Price {
            font-size: 1.4rem;
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            padding: 15px;
            @include pc {
                font-size: 1.4rem;
                padding: 0;
            }
            span {
                padding-left: 38px;
                background: url(/business/assets/img/ic_triangle_yellow_sp.png) left center no-repeat;
                background-size: 28px 28px;
                min-height: 28px;
                display: inline-block;
                line-height: 28px;
                font-weight: bold;

                @include pc {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    padding-left: 54px;
                    background: url(/business/assets/img/ic_triangle_yellow_pc.png) left center no-repeat;
                    background-size: 44px 44px;
                    min-height: 44px;
                    display: inline-block;
                    line-height: 44px;
                    font-weight: normal;
                }

                &#{$this}PriceCross {
                    background: url(/business/assets/img/ic_cross_sp.png) left center no-repeat;
                    background-size: 28px 28px;

                    @include pc {
                        background: url(/business/assets/img/ic_cross_pc.png) left center no-repeat;
                        background-size: 44px 44px;
                    }
                }
            }
            strong {
                font-size: 1.4rem;
                font-weight: bold;
                @include pc {
                    font-size: 1.8rem;
                    font-weight: normal;
                }
            }
            &Strong {
                span, strong {
                    @include pc {
                        font-weight: bold;
                    }
                }
            }
        }
        &Text {
            font-size: 1.4rem;
            padding: 8px 15px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            min-height: 60px;

            @include pc {
                font-size: 1.4rem;
                padding: 0 0 0 15px;
            }

            span {
                position: relative;
                padding-left: 38px;
                background: url(/business/assets/img/ic_triangle_sp.png) left center no-repeat;
                background-size: 28px 28px;
                line-height: 1.6;
                min-height: 28px;
                display: flex;
                align-items: center;
                @include pc {
                    padding-left: 54px;
                    background: url(/business/assets/img/ic_triangle_yellow_pc.png) left center no-repeat;
                    background-size: 44px 44px;
                    min-height: 44px;
                }

                &#{$this}TextCircle {
                    background: url(/business/assets/img/ic_circle_sp.png) left center no-repeat;
                    background-size: 28px 28px;
                    @include pc {
                        background: url(/business/assets/img/ic_circle_pc.png) left center no-repeat;
                        background-size: 44px 44px;
                    }
                }
            }

            br {
                display: none;

                @include pc {
                    display: block;
                }
            }
        }
        &Col {
            margin-top: 20px;
            @include pc {
                width: 253px;
                margin-top: 18px;
            }
            &--erikura {
                margin-top: 0;

                @include pc {
                    width: 341px;
                    margin-top: 0;
                }
                #{$this}Heading {
                    background: $yellowOrange;
                    @include pc {
                        height: 64px;
                        line-height: 64px;
                        font-size: 24px;
                        border-radius: 14px 14px 0 0;
                        border-right: none;
                    }
                }
                #{$this}Detail {
                    border-color: $yellowOrange;
                    @include pc {
                        border-left-width: 2px;
                        border-radius: 0;
                    }
                }
                #{$this}Price {
                    font-size: 1.6rem;
                    padding: 15px 15px 0;
                    font-weight: bold;
                    text-align: center;
                    @include pc {
                        font-size: 2.2rem;
                        padding-top: 20px;
                    }
                    span {
                        background: none;
                        padding-left: 0;
                        justify-content: center;
                    }
                    strong {
                        font-size: 3.0rem;
                        background: url(/business/assets/img/ic_circle_sp.png) left center no-repeat;
                        background-size: 28px 28px;
                        padding-left: 38px;
                        @include pc {
                            font-size: 4.0rem;
                            background: url(/business/assets/img/ic_circle_pc.png) left center no-repeat;
                            background-size: 44px 44px;
                            height: 44px;
                            display: inline-block;
                            line-height: 44px;
                            padding-left: 54px;
                        }
                    }
                }
                #{$this}Text {
                    font-size: 1.6rem;
                    font-weight: bold;
                    padding: 10px 15px;
                    @include pc {
                        font-size: 1.6rem;
                        line-height: 1.5;
                        padding: 0 0 0 15px;
                    }
                    span {
                        background: url(/business/assets/img/ic_circle_sp.png) left center no-repeat;
                        background-size: 28px 28px;
                        @include pc {
                            background: url(/business/assets/img/ic_circle_pc.png) left center no-repeat;
                            background-size: 44px 44px;
                        }
                    }
                }
            }
            &:last-child {
                #{$this}Heading {
                    @include pc {
                        border-radius: 0 14px 0 0;
                        border-right: none;
                    }
                }
                #{$this}Detail {
                    @include pc {
                        border-radius: 0 0 14px 0;
                    }
                }
                #{$this}Box:last-child {
                    @include pc {
                        border-radius: 0 0 12px 0;
                    }
                }
            }
        }
        &Point {
            position: relative;
            margin-top: 25px;
            padding: 0 15px 15px;
            width: 100%;

            @include pc {
                width: calc(100% - 40px);
                padding: 0 0 15px;
            }

            &Label {
                width: 86px;
                height: 30px;
                background: $yellowOrange;
                line-height: 30px;
                text-align: center;
                border-radius: 15px;
                font-weight: bold;
                color: $white;
                font-size: 1.4rem;
                position: absolute;
                top: -15px;
                left: 50%;
                margin-left: -43px;
            }

            &Text {
                border: 4px solid $blueGray;
                border-radius: 10px;
                font-weight: bold;
                font-size: 1.4rem;
                text-align: center;
                padding: 20px 15px 12px;

                span {
                    font-size: 1.8rem;
                }

                strong {
                    color: $orange;
                    font-size: 1.8rem;
                }
            }
        }
        &Merit {
            position: relative;
            background: $white;
            border: 3px solid $yellowOrange;
            border-radius: 20px;
            text-align: center;
            line-height: 1.6;
            font-size: 1.6rem;
            padding: 15px 0;
            margin-top: 10px;

            @include pc {
                left: -43px;
                top: 15px;
                width: 419px;
                border-width: 4px;
                margin-top: 0;
                font-size: 1.8rem;
                padding: 17px 0;
            }

            span {
                color: $orange;
                font-weight: bold;
            }

            &:after, &:before {
                bottom: 100%;
                left: 50%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }

            &:after {
                border-color: rgba(255, 255, 255, 0);
                border-bottom-color: $white;
                border-width: 5px;
                margin-left: -5px;

                @include pc {
                    border-width: 10px;
                    margin-left: -10px;
                }
            }
            &:before {
                border-color: rgba(255, 164, 0, 0);
                border-bottom-color: $yellowOrange;
                border-width: 9px;
                margin-left: -9px;

                @include pc {
                    border-width: 16px;
                    margin-left: -16px;
                }
            }
        }
        &Notes {
            font-size: 1.2rem;
            text-align: center;
            margin-bottom: 10px;
            @include pc {
                margin-bottom: 0;
                font-size: 1.4rem;
                position: absolute;
                top: -20px;
                right: 0;
            }
        }
    }
    &__costPerformanceSimple {
        padding-top: 50px;

        @include pc {
            padding-top: 85px;
        }

        &List {
            font-size: 1.4rem;

            @include pc {
                display: flex;
                width: 950px;
                margin: 0 auto;
                font-size: 1.6rem;
            }
            & > li {
                background-color: $lightGray;
                padding: 20px;
                border-radius: 20px;
                margin-bottom: 20px;

                @include pc {
                    margin: 0 20px 0 0;
                    padding: 20px 25px 25px;
                    width: 33.3%;
                }

                &:last-of-type {
                    margin-bottom: 0;

                    @include pc {
                        margin-right: 0;
                    }
                }

                &:nth-child(1) figure img {
                    width: 70px;

                    @include pc {
                        width: 100px;
                    }
                }
                &:nth-child(2) figure img {
                    width: 50px;

                    @include pc {
                        width: 64px;
                    }
                }
                &:nth-child(3) figure img {
                    width: 50px;

                    @include pc {
                        width: 72px;
                    }
                }

                figure {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 62px;
                    margin: 20px 0;

                    @include pc {
                        height: 110px;
                        margin: auto;
                    }
                }
            }

            strong {
                font-weight: $font-bold;
            }
        }

        &Title {
            text-align: center;

            img {
                height: 50px;

                @include pc {
                    height: 72px;
                }
            }
        }
    }

    &__management {
        background-color: $lightGray;
        padding: 30px 20px 40px;

        @include pc {
            padding: 50px 0 70px;
        }

        > div {
            @include pc {
                width: 950px;
                margin: 0 auto;
            }
        }

        .p-lp__sectionTtl {
            > img {
                height: 50px;
                @include pc {
                    height: 100px;
                }
            }
        }

        &Wrap {
            @include pc {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &Img {
            width: 260px;
            margin: 0 auto;

            @include pc {
                width: 405px;
                margin: inherit;
            }

            img {
                width: 100%;
            }
        }

        &Text {
            background: $white;
            font-size: 1.4rem;
            padding: 44px 20px 30px;
            border-radius: 20px;
            margin-top: -24px;

            @include pc {
                font-size: 1.6rem;
                width: 455px;
                padding: 50px 35px;
                border-radius: 0 20px 20px 0;
                margin-top: 0;
            }
        }
    }
    &__excludingTax {
        text-align: right;
        font-size: 1.2rem;
        margin-top: 5px;

        @include pc {
            font-size: 1.4rem;
        }
    }

    &__contract {

        &Step {
            background-color: $lightGray;
            margin: 30px auto 40px;
            padding: 20px 10px;

            @include pc {
                width: 950px;
                box-sizing: border-box;
                padding: 20px;
                margin: 30px auto;
            }

            &Title {
                font-size: 1.4rem;
                font-weight: bold;
                text-align: center;
                margin-bottom: 25px;

                @include pc {
                    font-size: 1.8rem;
                }
            }

            &List {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            &Item {
                width: calc(50% - 5px);
                margin-bottom: 35px;
                position: relative;

                @include pc {
                    width: calc(25% - 15px);
                    margin-bottom: 0;
                }

                &:nth-last-of-type(2) {
                    margin-bottom: 0;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            &Num {
                background-color: $emerald;
                color: $white;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                font-size: 1.6rem;
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                top: -15px;

                @include pc {
                    width: 40px;
                    height: 40px;
                    font-size: 2.2rem;
                    top: -20px;
                }
            }

            &Img {
                display: block;
                width: 100%;
                margin-bottom: 10px;
            }

            &Process {
                font-size: 1.2rem;
                font-weight: bold;
                text-align: center;
                margin-bottom: 5px;
                line-height: 1.6;

                @include pc {
                    font-size: 1.6rem;
                    line-height: 1.6;
                    margin-bottom: 10px;
                }
            }

            &Paragraph {
                font-size: 1.1rem;
                line-height: 1.6;

                @include pc {
                    font-size: 1.4rem;
                    line-height: 1.6;
                }
            }
        }
    }

    &__workWorries {
        background-color: $white;
        margin: 40px 20px;
        @include pc {
            width: 950px;
            margin: 50px auto 60px;
        }
        .p-lp__sectionTtl {
            img {
                height: 50px;
                max-width: 100%;
                @include pc {
                    height: 90px;
                }
            }
        }
        &List {
            @include pc {
                display: flex;
                width: 950px;
                margin: 0 auto;
            }
            & > li {
                background-color: $lightGray;
                padding: 20px;
                border-radius: 20px;
                margin-bottom: 20px;
                @include pc {
                    margin: 0 20px 0 0;
                    padding: 30px 25px 30px;
                    width: 33.3%;
                }

                &:last-of-type {
                    margin-bottom: 0;
                    @include pc {
                        margin-right: 0;
                    }
                }
            }
        }
        &Title {
            text-align: center;
            margin-bottom: 20px;
            img {
                height: 50px;
                @include pc {
                    height: 55px;
                }
            }
        }
        &Text {
            position: relative;
            padding-left: 25px;
            font-size: 1.4rem;
            @include pc {
                font-size: 1.6rem;
            }
            & + & {
                margin-top: 10px;
            }
            &:before {
                content: '';
                width: 8px;
                height: 8px;
                background: #00C6B7;
                border-radius: 4px;
                display: block;
                position: absolute;
                top: 10px;
                left: 4px;
            }
        }
    }

    &__features {
        $this: &;
        margin: 50px 0;
        &Text {
            text-align: center;
            margin-bottom: 20px;
            @include pc {
                margin-bottom: 30px;
            }
            img {
                height: 21px;
                max-width: 100%;
                vertical-align: top;
                @include pc {
                    height: 29px;
                }
            }
        }
        &Btns {
            display: flex;
            border-radius: 4px;
            box-shadow: 0 2px 10px 0 rgba(0,0,0,0.10);
            margin: 0 20px;
            margin-bottom: -49px;
            @include pc {
                width: 950px;
                margin: 0 auto 50px;
                margin-bottom: -45px;
            }
            & > li {
                width: 33.333%;
                text-align: center;
                &:first-child {
                    #{$this}Btn {
                        border-radius: 4px 0 0 4px;
                        border-left: 1px solid $gray;
                    }
                }
                &:last-child {
                    #{$this}Btn {
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
        }
        &Btn {
            padding: 12px 0 27px;
            background: $white;
            display: block;
            border: 1px solid $gray;
            border-left-width: 0px;
            color: $black;
            font-weight: $font-bold;
            position: relative;
            font-size: 1.4rem;
            line-height: 1.2;
            @include pc {
                padding: 12px 12px 27px;
                font-size: 2.0rem;
                line-height: 1.6;
            }
            &:after {
                content: '';
                width: 16px;
                height: 12px;
                margin-left: -8px;
                background: url(/business/assets/img/ic_link_triangle_orange_bottom.svg);
                display: block;
                position: absolute;
                bottom: 11px;
                left: 50%;
            }
        }
        &Number {
            height: 17px;
            margin-bottom: 6px;
            @include pc {
                margin-bottom: 0;
            }
            img {
                height: 100%;
                vertical-align: top;
            }
        }
    }

    &__featuresContents {
        background-color: $lightGray;
        padding: 49px 0 50px;
        @include pc {
            padding: 45px 0 85px;
        }
    }

    &__featuresContent {
        $this: &;
        padding-top: 40px;
        @include pc {
            padding-top: 50px;
        }
        &Title {
            text-align: center;
            margin-bottom: 20px;
            @include pc {
                margin-bottom: 30px;
            }
            img {
                height: 69px;
                @include pc {
                    height: 88px;
                }
            }
        }
        &Detail {
            background: $white;
            border-radius: 20px;
            text-align: center;
            padding: 20px;
            margin: 0 20px;
            &+ & {
                margin-top: 20px;
            }
            @include pc {
                width: 850px;
                padding: 30px 50px;
                margin: 0 auto;
            }

            &--manegement {
                overflow: hidden;
                #{$this}SubTitle {
                    @include pc {
                        margin-top: 20px;
                        width: 560px;
                        float: right;
                        text-align: left;
                    }
                }
                #{$this}Image {
                    width: 100%;
                    @include pc {
                        width: 260px;
                        float: left;
                    }
                    img {
                        width: 100%;
                    }
                }
                #{$this}Text {
                    text-align: left;
                    margin-top: 15px;
                    @include pc {
                        margin-top: 0;
                        width: 560px;
                        float: right;
                    }
                }
            }
        }
        &Text {
            line-height: 1.6;
            &--strong {
                font-size: 1.4rem;
                background: $lightYellow;
                display: inline-block;
                padding: 0 5px;
                font-weight: $font-bold;
                margin-bottom: 10px;
                @include pc {
                    font-size: 2.0rem;
                }
            }
        }
        &Graph {
            margin: 15px 0 10px;
            display: block;
            @include pc {
                margin: 30px 0 10px;
            }
            img {
                width: 100%;
                @include pc {
                    width: 730px;
                }
            }
        }
        &Notes {
            font-size: 1.2rem;
            text-align: center;
            margin: 10px 20px 0;
            @include pc {
                width: 950px;
                margin: 10px auto 0;
                font-size: 1.4rem;
                text-align: right;
            }
        }
        &SubTitle {
            text-align: center;
            margin-bottom: 15px;
            @include pc {
                margin-bottom: 20px;
            }
            img {
                height: 20px;
                vertical-align: top;
                @include pc {
                    height: 30px;
                }
            }
        }
    }

    &__pdf {
        margin-top: 20px;
        &Title {
            padding: 10px;
            background: $yellowOrange;
            font-size: 1.8rem;
            font-weight: $font-bold;
            border-radius: 14px 14px 0 0;
            @include pc {
                font-size: 2.0rem;
            }
        }
        &List {
            width: 100%;
            padding: 20px;
            border: 3px solid $yellowOrange;
            border-top-width: 0;
            border-radius: 0 0 14px 14px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            box-sizing: border-box;
            @include pc {
                padding: 15px 52px 30px;
            }
        }
        &Item {
            width: 100%;
            @include pc {
                width: 23.7%;
                margin: 0;
                margin-top: 15px;
            }
            & + & {
                margin-top: 10px;
                @include pc {
                    margin-top: 15px;
                }
            }
        }
        &Btn {
            width: 100%;
            height: 60px;
            background: $white;
            border: 1px solid $gray;
            box-shadow: 0 2px 10px rgba(0, 0, 0, .1);
            border-radius: 4px;
            padding: 0 15px 0 70px;
            position: relative;
            font-size: 1.4rem;
            line-height: 1.4;
            font-weight: $font-bold;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            @include pc {
                height: 70px;
            }
            img {
                height: 40px;
                margin-top: -20px;
                position: absolute;
                top: 50%;
                left: 15px;
                @include pc {
                    height: 50px;
                    margin-top: -25px;
                    top: 50%;
                }
            }
            &Text {
                width: 100%;
                > span {
                    width: 100%;
                    font-size: 1.2rem;
                    color: $emerald;
                    display: block;
                }
            }
        }
    }
}

.swiper-container {
    width: 100%;
    height: 390px;
    box-sizing: border-box;

    @include pc {
        height: 440px;
    }
}
.swiper-pagination-bullet {
    background-color: #666;
    border: solid 1px $white;
}
.swiper-pagination-bullet-active {
    background-color: $orange;
    border: solid 1px $white;
}
.parsley-custom-error-message {
    color: #FF4242;
    background-color: #FFF1F1;
    padding: 6px;
    margin-top: 10px;
    font-size: 1.2rem;
    @include pc {
        font-size: 1.4rem;
    }
}