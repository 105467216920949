.c-required {
    background: $white;
    border: 1px solid #FF4242;
    border-radius: 4px;
    font-size: 1.0rem;
    color: #FF4242;
    text-align: center;
    display: inline-block;
    width: 38px;
    height: 16px;
    line-height: 16px;
    font-weight: bold;
    margin-right: 10px;
    vertical-align: middle;
}

.c-partnerError {
    display: none;
}